import { is_empty } from "./validations";

/**
 * Compares two objects and returns a new object containing keys and values from the source object
 * that have been updated (values differ) in comparison to the target object.
 *
 * NOTE: This function compares only values at deplth 1 of the object.
 * TODO: Update(or create a new one) this function to handle for depth > 1
 *
 * @param {object} source - The source object to compare.
 * @param {object} target - The target object to compare against.
 * @returns {object} An object containing updated key-value pairs from the source object.
 */
export const getUpdatedValuesInObject = (source, target) => {
  if (is_empty(target)) return source;

  const updatedValues = {};
  for (const key in source) {
    const sourceValue = source[key];
    const targetValue = target[key];
    if (sourceValue !== targetValue) updatedValues[key] = sourceValue;
  }
  return updatedValues;
};

/**
 *
 * Compares two objects and checks if the keys and values from the source object
 * are same as that of target object.
 *
 * NOTE: This function compares only values at deplth 1 of the object.
 * TODO: Update(or create a new one) this function to handle for depth > 1
 *
 * @param {object} source - The source object to compare.
 * @param {object} target - The target object to compare against.
 * @returns - `true` if all the key-value pairs in source object are exactly same as that of target object.
 *          - `false` if any key-value pair in source object differs from that of target object.
 */
export const checkObjectHasEqualValues = (source, target) => {
  if (is_empty(target)) return is_empty(source);

  for (const key in source) {
    const sourceValue = source[key];
    const targetValue = target[key];
    if (sourceValue !== targetValue) return false;
  }
  return true;
};

export const arrayHasOneElement = (source) => {
  return source.length === 1;
};
