import {
  EXLY_GLOBAL__POP_NOTIFICATION,
  EXLY_GLOBAL__PUSH_NOTIFICATION,
} from "redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { isFunction } from "utils/validations";

/**
 * to be used with `notify`
 */
export const notification_color_keys = {
  success: "success",
  info: "info",
  error: "error",
  warning: "warning",
};

/**
 * to be used with `pushNotification`
 */
export const notification_color_map = {
  success: "fine_pine",
  info: "primary",
  error: "coral_red",
  warning: "butterscotch_yellow",
};

export const notification_variant_map = {
  default: "default",
  outlined: "outlined",
};

/**
 * A hook to centrally manage notifications across the project.
 * @returns notificationList - List of notifications being shown
 * @returns pushNotification - Add a notification
 * @returns deleteNotification - Delete a notification using the id
 */
export const useNotifications = () => {
  const dispatch = useDispatch();

  const notificationList = useSelector(
    (state) => state.global.notificationList
  );
  const pushToQueueAction = (payload) => {
    dispatch({ type: EXLY_GLOBAL__PUSH_NOTIFICATION, payload });
  };

  const popFromQueueAction = (id) => {
    dispatch({ type: EXLY_GLOBAL__POP_NOTIFICATION, payload: { id } });
  };

  const deleteNotification = (notificationId) => {
    popFromQueueAction(notificationId);
  };

  /**
   * @param {*} message content to display. Can be text or a JSX element
   * @param {*} options Configuring the toast notification
   * @param {*} options.id Has to be unique. If duplicate, will replace an old notification with the same ID and a reset timer.
   * @param {*} options.color Color of the <Toast /> component
   * @param {*} options.simplifiedNotificationColor simplified Color of the <Toast /> component. Uses `color_map`
   * @param {*} options.variant Variant of the <Toast /> component
   * @param {*} options.timeout after what duration to remove the notification from the queue. Denoted in milliseconds
   * @param {*} options.onDelete after what duration to remove the notification from the queue. Denoted in milliseconds
   * @param {*} options.otherProps any other props for the <Toast /> component
   */

  const pushNotification = (message, options = {}) => {
    const notificationData = {
      id: options.id || `${Date.now()}`,
      onDelete: options.onDelete,
      timeout: options.timeout ?? 5000,
      props: {
        color:
          notification_color_map[options.simplifiedNotificationColor] ||
          options.color ||
          "primary",
        variant: options.variant || "default",
        label: message,
        ...(options.otherProps || {}),
      },
    };
    const { timeout, id } = notificationData;

    pushToQueueAction({
      [notificationData.id]: {
        ...notificationData,
        onDelete: () => {
          deleteNotification(notificationData.id);
          if (isFunction(notificationData?.onDelete)) {
            notificationData.onDelete();
          }
        },
      },
    });

    const idCopy = `${id}`;

    if (timeout !== Infinity) {
      setTimeout(() => {
        deleteNotification(idCopy);
      }, timeout);
    }

    return notificationData;
  };

  // this is a wrapper function over pushNotification, simplifying usage to show notifications
  const notify = (message, color) => {
    pushNotification(message, {
      color: notification_color_map[color || "info"],
      variant: notification_variant_map.outlined,
    });
  };

  return {
    notificationList,
    pushNotification,
    deleteNotification,
    notify,
  };
};
