/**Below import statement is to be used when sentry integration will be done in creator tool */
// import * as Sentry from "@sentry/nextjs";
/**
```
{
    error?: any;
    extraErrorData?: any;
    occuredAt?: string;
    when?: string;
    severity?: "low" | "medium" | "critical" | "blocker";
    customErrorMessage?: string;
    logToPlatforms?: Array<IConsoleItem | ISentryItem>;
}
type ISentryItem = ["sentry"] | ["sentry", { forceLogStacktrace?: boolean }];
type IConsoleItem = ["console"];
```
 */

export const logError = async ({
  error,
  extraErrorData = undefined,
  occuredAt,
  when,
  severity = "medium",
  customErrorMessage = undefined,
  logToPlatforms = [["console"]],
}) => {
  const customError = customErrorMessage || {
    error,
    occuredAt,
    when,
    severity,
    extraErrorData,
  };

  logToPlatforms.forEach(([platform]) => {
    if (platform.includes("console")) {
      console.log(customError);
    }
    /**This code is to be used when sentry integration will be done in creator tool */
    // if (platform.includes("sentry")) {
    //     // do we want to log the stacktrace instead of logging the custom object?
    //     // https://docs.sentry.io/platforms/javascript/guides/nextjs/usage/#capturing-errors
    //     if (error instanceof Error && platformOptions?.forceLogStacktrace) {
    //         Sentry.captureException(error);
    //     } else {
    //         Sentry.captureException(customError);
    //     }
    // }
  });
};
