import { RECEIPT_URL } from "constants/schedule";
import { getS3SignedUrl } from "./Utils";
import { is_empty } from "./validations";

/**
 * convert the simple URL to the signed S3 URL for open/download the file
 * @param {object} record: table record row data
 * @param {string} source: key i which data is coming
 * @returns
 */
export const getSignedS3DownlaodURL = async (record, source = RECEIPT_URL) => {
  if (is_empty(record[source])) return;
  const newUrl = new URL(record[source]);
  const { pathname: PdfFile } = newUrl;
  // substring(1) for removing "/" comes in pathname
  const url = await getS3SignedUrl(PdfFile.substring(1));
  if (url) window.open(url);
};
