import constants from "constants/constants";
import {
  accepted_image_formats,
  accepted_non_image_formats,
  recorded_content_media_type_details,
  recorded_content_media_types,
  video_formats,
  watermarkable_image_formats,
} from "constants/recordedContent";
import { api, dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { exly_env_name } from "features/Common/modules/Environment/Environment.constants";
import { ADD_MEDIA_LIB_CONTENT_API } from "features/MediaLibrary/constants/MediaLibrary.api";
import { useEffect, useState } from "react";
import { RECORDED_CONTENT_VIDEO_SOURCE } from "schedule-v2/RecordedContent/recordedContent.data";
import { is_empty } from "utils/validations";
import { getAuthData } from "../AuthUtil";
import {
  fileTypeIsAnyOfMIMETypes,
  getFileFormat,
  getFileNameWOFormat,
  isMIMETypePDF,
  parseFileNameForUploadOrDownload,
} from "../fileUtils";
import { getS3SignedUrl } from "../Utils";

const isMIMETypeImage = (fileType) =>
  fileTypeIsAnyOfMIMETypes(fileType, accepted_image_formats);

const isMIMETypeVideo = (fileType) =>
  fileTypeIsAnyOfMIMETypes(fileType, video_formats);

const isWatermarkableImageFormat = (fileFormat) =>
  watermarkable_image_formats.includes(fileFormat);

const isMIMETypeDoc = (fileType) =>
  fileTypeIsAnyOfMIMETypes(fileType, accepted_non_image_formats);

export const getS3KeyForRecordedContent = (file = {}) => {
  const fileName = file.name || "";
  const fileNameForUpload = parseFileNameForUploadOrDownload(fileName, 85);
  const fileType = file.type || "";
  const creatorUuid = getAuthData()?.username;
  const fileFormat = getFileFormat(fileName);
  let fileTypeFolderName = fileFormat;
  if (isMIMETypeImage(fileType) && isWatermarkableImageFormat(fileFormat)) {
    fileTypeFolderName = "img";
  } else if (isMIMETypePDF(fileType)) {
    fileTypeFolderName = "pdf";
  }

  return `unprocessed/${exly_env_name}/${creatorUuid}/${fileTypeFolderName}/${Date.now()}/${getFileNameWOFormat(
    fileNameForUpload
  )}_${Date.now()}.${fileFormat}`;
};

export const getS3KeyForRecordedContentVideo = (file = {}) => {
  const fileName = getFileNameWOFormat(file.name || "");
  const fileFormat = getFileFormat(file.name || "");

  return `videos/${fileName}_${Date.now()}.${fileFormat}`;
};

export const getS3BucketForRecordedContent = (file = {}) => {
  const fileName = file.name || "";
  const fileType = file.type || "";
  const fileFormat = getFileFormat(fileName);
  if (isMIMETypeImage(fileType) && isWatermarkableImageFormat(fileFormat)) {
    return process.env.REACT_APP_EXLY_S3_BUCKET_WATERMARK_IMAGE;
  } else if (isMIMETypePDF(fileType)) {
    return process.env.REACT_APP_EXLY_S3_BUCKET_WATERMARK_PDF;
  }
  return process.env.REACT_APP_EXLY_S3_BUCKET_UNWATERMARKED_FILES;
};

export const getPrivateS3Bucket = (url) =>
  [
    process.env.REACT_APP_EXLY_S3_BUCKET_WATERMARK_PDF,
    process.env.REACT_APP_EXLY_S3_BUCKET_WATERMARK_IMAGE,
    process.env.REACT_APP_EXLY_S3_BUCKET_UNWATERMARKED_FILES,
  ].find((i) =>
    url.startsWith(
      `https://${i}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com`
    )
  );

/**
 * return previewable media item url
 * - if media item is uploaded in private S3 bucket, fetch it from s3 and return
 *    - `processing === true` while fetching
 * - else return it as it is
 */
export const fetchPreviewableMediaItemUrl = ({
  url,
  onSuccess = () => {},
  finallyCallback = () => {},
}) => {
  const privateBucket = getPrivateS3Bucket(url);
  if (privateBucket) {
    getS3SignedUrl(
      url.split("amazonaws.com/")[1],
      privateBucket,
      constants.RECORDED_CONTENT_UPLOAD_AWS_CRED
    )
      .then((apiResponse) => {
        onSuccess({ previewableUrl: apiResponse });
      })
      .catch((err) => {
        console.log(
          `error occured while calling fetchMediaItemUrl;
          url: ${url};
          err: ${err};
          `
        );
      })
      .finally(finallyCallback);
  } else {
    onSuccess({ previewableUrl: url });
    finallyCallback();
  }
};

/**
 * return previewable media item url
 * - if media item is uploaded in private S3 bucket, fetch it from s3 and return
 *    - `processing === true` while fetching
 * - else return it as it is
 */
export const useFetchPreviewableMediaItemUrl = (url) => {
  const [previewableUrl, setPreviewableUrl] = useState(undefined);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!url) return;
    setProcessing(true);
    fetchPreviewableMediaItemUrl({
      url,
      onSuccess: ({ previewableUrl: previewableUrlArg }) => {
        setPreviewableUrl(previewableUrlArg);
      },
      finallyCallback: () => {
        setProcessing(false);
      },
    });
  }, [url]);

  if (!url) return { previewableUrl: null, processing: false };
  return { previewableUrl, processing };
};

const getMediaTypeProperty = (mediaType, key) =>
  recorded_content_media_type_details?.[mediaType]?.[key];

export const getMediaTypeName = (mediaType) =>
  getMediaTypeProperty(mediaType, "name");

export const getMediaTypeImgSrc = (mediaType) =>
  getMediaTypeProperty(mediaType, "imgSrc");

export const getMediaTypeFromMIMEType = (fileType) => {
  if (isMIMETypeImage(fileType)) return recorded_content_media_types.image;
  if (isMIMETypePDF(fileType)) return recorded_content_media_types.pdf;
  // isMIMETypeDoc internally checks for `pdf` as well... therefore `isMIMETypePDF` check should be done before `isMIMETypeDoc`
  if (isMIMETypeDoc(fileType)) return recorded_content_media_types.attachment;
  if (isMIMETypeVideo(fileType)) return recorded_content_media_types.video;
  return null;
};

export const isIframe = (iframe) => {
  let speedExamRegex = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/;
  return speedExamRegex.test(iframe);
};

export const validateIframe = (value) => {
  if (!isIframe(value)) {
    return "Please enter a valid embeded code";
  }
};

export const getVdoCipherVideoOTP = async (videoId) => {
  if (is_empty(videoId)) throw new Error("Video id not provided");

  const response = await dataProvider.custom_request(
    api.vdocipher.otp,
    apiMethods.POST,
    { video_id: videoId }
  );

  return response.data;
};

export const getVdoCipherVideoStatus = async (videoId) => {
  if (is_empty(videoId)) throw new Error("Video id not provided");

  const response = await dataProvider.custom_request(
    api.vdocipher.status,
    apiMethods.GET,
    { video_id: videoId }
  );

  return response.data;
};

export const addContentToMediaLibrary = ({
  fileName,
  mediaType,
  fileLocation, // if (video source is VdoCipher): contains Video ID; else: Video URL
  videoSource,
}) => {
  return dataProvider.custom_request(
    ADD_MEDIA_LIB_CONTENT_API,
    apiMethods.POST,
    {
      title: fileName,
      type: mediaType,
      content_url: fileLocation,
      video_source: videoSource,
    }
  );
};

/**
 * Checks whether the provided video source corresponds to VdoCipher non-DRM content.
 *
 * @param {number} videoSource - The video source associated with the content.
 * @returns {boolean} - Returns true if the video source is VdoCipher non-DRM, otherwise false.
 */
export const isVideoSourceVdoCipherNonDrm = (videoSource) =>
  videoSource === RECORDED_CONTENT_VIDEO_SOURCE.VDOCIPHER_NON_DRM;
