import { useState, useEffect } from "react";
import constants from "constants/constants";
import { dataProvider, api } from "data";
import { useNotifications } from "./useNotifications";

export const useGSTStatus = () => {
    const { pushNotification } = useNotifications();
    const [gst_status, set_gst_status] = useState(
        constants.GST_STATUS.INAPPLICABLE
    );

    useEffect(() => {
        try {
            const cachedStatus = window.localStorage.getItem("gst_status");
            if (
                !cachedStatus ||
                !Object.values(constants.GST_STATUS).includes(
                    Number(cachedStatus)
                )
            ) {
                (async () => {
                    const bankData = await dataProvider.custom_request(
                        `${api.user_payout_details}`
                    );
                    if (bankData.status === 200) {
                        set_gst_status(
                            bankData.data.account_details.gst_status
                        );
                        window.localStorage.setItem(
                            "gst_status",
                            bankData.data.account_details.gst_status
                        );
                    }
                })();
            } else {
                set_gst_status(Number(cachedStatus));
            }
        } catch (err) {
            pushNotification(
                err?.body?.message ||
                    "An error occurred. Please refresh the page and try again.",
                { variant: "outlined", color: "coral_red" }
            );
        }
    }, []);

    return gst_status;
};
