import constants from "constants/constants";
import {
  DEFAULT_PAYMENT_CYCLE,
  PAYMENT_PLAN_TYPE,
  PRICING_MODE_KEYS,
  schedule_types_ids,
} from "constants/schedule";
import { isInteger, is_empty } from "utils/validations";

/**
 *
 * @param {*} offering object containing type and  course_end_magnitude key
 * @returns whether the offering has renewal payment type
 */
export const isSubscriptionPaymentType = (offering) => {
  return (
    !is_empty(offering) &&
    ![schedule_types_ids.content].includes(offering?.type) &&
    isInteger(offering?.course_end_magnitude) &&
    offering?.course_end_magnitude > DEFAULT_PAYMENT_CYCLE
  );
};

/**
 *
 * @param {*} offering offering object containing type and  course_end_magnitude key
 * @returns whether the offering is qpp with subscription type
 */
export const isQppSubscription = (offering) =>
  offering?.type === schedule_types_ids.no_schedule &&
  isSubscriptionPaymentType(offering);

/**
 *
 * @param {*} offering offering object containing type and  pricing_mode key
 */
export const isFlexibleOneTime = (offering) => {
  return (
    offering?.type === schedule_types_ids.rolling_class &&
    offering?.pricing_mode === PRICING_MODE_KEYS.oneTime &&
    offering?.payment_plan_type !== PAYMENT_PLAN_TYPE.SUBSCRIPTIONS
  );
};

export const isClassType = (type) => {
  return (
    type === constants?.scheduleTypesId?.group_class ||
    type === constants?.scheduleTypesId?.one_on_one_class ||
    type === constants?.scheduleTypesId?.rolling_class
  );
};
