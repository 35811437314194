import {
  file_format_keys,
  file_mime_types,
  video_format_keys,
} from "features/Common/modules/File/File.constants";
import { appendUrlParams } from "./urlUtils";
import { is_empty } from "./validations";

export const isImage = (type) => {
  return ["image/jpeg", "image/png", "image/webp", "photo"].includes(type);
};

export const fileTypeIsOfMIMEType = (fileType = "", fileFormatKey = "") =>
  (file_mime_types[fileFormatKey] || []).includes(fileType);

export const fileTypeIsAnyOfMIMETypes = (fileType = "", fileFormatKeys = []) =>
  fileFormatKeys.some((fileFormatKey) =>
    fileTypeIsOfMIMEType(fileType, fileFormatKey)
  );

export const isMIMETypePDF = (type) =>
  fileTypeIsOfMIMEType(type, file_format_keys.pdf);

export const isDocument = (type) => {
  return (
    isMIMETypePDF(type) ||
    fileTypeIsAnyOfMIMETypes(type, [
      file_format_keys.doc,
      file_format_keys.docx,
      file_format_keys.csv,
    ]) ||
    // TODO: update following, when mime_types are added
    ["document", "text/plain", "file"].includes(type)
  );
};

export const isVideo = (type) => {
  return ["video"].includes(type);
};

export function isVideoFile(file) {
  const fileType = file?.type || "";
  return (
    fileType.startsWith("video/") ||
    file?.name?.endsWith(`.${video_format_keys.ts}`) // getting an empty string ("") as the type in the file object for a .ts video file, so checking it with extension
  );
}

export const getFileFormat = (fileName) => {
  const indexOfLastDot = fileName.lastIndexOf(".");
  if (indexOfLastDot > -1) {
    return fileName.slice(indexOfLastDot + 1);
  }
};

export const getFileNameWOFormat = (fileName = "", fileFormat = "") => {
  const format = fileFormat || getFileFormat(fileName);
  if (format) {
    const dotFormat = "." + format;
    if (fileName.endsWith(dotFormat)) {
      return fileName.slice(0, -dotFormat.length);
    }
  }
  return fileName;
};

const default_replace_all_special_chars_options = {
  char: "_",
  replaceConsecutive: true,
};
export const replaceAllSpecialCharsWithChar = (str, options) => {
  const char = options?.char || default_replace_all_special_chars_options.char;
  const returnVal = str.replace(/[^a-zA-Z0-9]/g, char);

  const replaceConsecutive =
    options?.replaceConsecutive ||
    default_replace_all_special_chars_options.replaceConsecutive;
  if (replaceConsecutive) {
    returnVal.replace(/_{2,}/g, char); // replace consecutive `_____` by just one `_`
  }

  return returnVal;
};

/**
 * replaces all special characters(consecutive ones too) with just one `_` and can have name maxLength control
 * @param maxLength - send `0` to keep full name
 * @returns
 */
export const parseFileNameForUploadOrDownload = (fileName, maxLength = 10) => {
  const fileFormat = getFileFormat(fileName) || "";
  let fileNameWOFormat = replaceAllSpecialCharsWithChar(
    getFileNameWOFormat(fileName)
  );
  if (maxLength > 0) {
    let fileNameWOFormatMaxLength = maxLength - fileFormat.length - 1;
    fileNameWOFormatMaxLength =
      fileNameWOFormatMaxLength >= 0 ? fileNameWOFormatMaxLength : 0;
    fileNameWOFormat = fileNameWOFormat.slice(0, fileNameWOFormatMaxLength);
  }
  return `${fileNameWOFormat}.${fileFormat}`;
};

export const getDotFormat = (format) => `.${format}`;

export const getDotFormats = (formats = []) => formats.map(getDotFormat);

export const getFileSize = (size) => {
  return {
    fileSizeInKB: (size / 1024).toFixed(2),
    fileSizeInMB: (size / (1024 * 1024)).toFixed(2),
    fileSizeInGB: (size / (1024 * 1024 * 1024)).toFixed(2),
  };
};

export const getGoogleDriveFileIdFromUrl = (url) =>
  url.match(/[-\w]{25,}/)?.[0];

export const getGoogleDriveDownloadableUrl = (url) => {
  if (is_empty(url)) return "";
  const contentId = getGoogleDriveFileIdFromUrl(url) || "";
  return appendUrlParams("https://drive.google.com/uc", {
    id: contentId,
  });
};
