import { useState, useCallback } from "react";

export const useToggleState = (initialState) => {
  /**
   * A wrapper over useState for boolean only states which can be set on or off.
   * const [isPopupActive, showPopup, hidePopup] = useToggleState(false);
   */
  const [state, setState] = useState(initialState);
  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);
  const toggle = useCallback(() => setState((prevState) => !prevState), []);
  return [state, setTrue, setFalse, toggle];
};
