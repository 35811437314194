import { AssignMemberDropdown } from "common/Components/AssignMemberDropdown/AssignMemberDropdown";
import { empty_option } from "common/Components/AssignMemberDropdown/AssignMemberDropdown.constants";
import React from "react";
import {
  assignCustomMember,
  getCustomColumns,
} from "ui/pages/schedule/BookedSession/BookedSession.helpers";
import { custom_column_types } from "ui/pages/schedule/BookedSession/bookedSession.constants";
import useColumnsToShow from "../../features/Crm/utils/hooks/useColumnsToShow";

const useCustomColumnsConfig = ({
  sectionName,
  recordSlug,
  setScrollPosition,
}) => {
  const { columnsToShow = {} } = useColumnsToShow({
    getColumnsHelper: () => getCustomColumns(sectionName),
  });
  const { custom_columns: customColumns = [] } = columnsToShow;

  const getAssignMemberDropdowmProp = ({
    disabled,
    record,
    orgMembers,
    customColumnItem,
    recordSlug,
  }) => {
    const dropdownProps = {
      disabled,
      record,
      orgMembers,
      columnSlug: customColumnItem.column_slug,
      recordSlug,
      setScrollPosition,
      assignMemberOnChange: (e) =>
        assignCustomMember({
          value: e === empty_option.value ? null : e,
          targetObject: record[recordSlug],
          customColumnUuid: customColumnItem.uuid,
          columnSlug: customColumnItem.column_slug,
        }),
    };
    return dropdownProps;
  };

  const customColumnsHeaders = customColumns.map((obj) => obj.column_name);

  const appendCustomTableConfig = ({ orgMembers, disabled }) => {
    const customColumnsToRender = [];
    const customFiltersToAppend = [];
    const customChipsToFormat = [];
    const customFieldToRenderInDetails = [];
    customColumns.forEach((customColumnItem) => {
      if (
        customColumnItem.content_type ===
        custom_column_types.organisation_type.value
      ) {
        customColumnsToRender.push({
          field: customColumnItem.column_slug,
          headerName: customColumnItem.column_name,
          valueFormatter: (record) => (
            <AssignMemberDropdown
              {...getAssignMemberDropdowmProp({
                disabled,
                record,
                orgMembers,
                customColumnItem,
                recordSlug,
              })}
            />
          ),
          width: "15%",
          noWrap: true,
        });
        customFiltersToAppend.push({
          source: customColumnItem.column_slug,
          placeholder: customColumnItem.column_name,
          type: "dropdown",
          options: orgMembers,
          disableContains: true,
          hidden: !customColumnItem.is_filter_applicable,
        });
        customChipsToFormat.push({
          columnSlug: customColumnItem.column_slug,
          columnName: customColumnItem.column_name,
        });
        customFieldToRenderInDetails.push({
          field: customColumnItem.column_slug,
          fieldName: customColumnItem.column_name,
          fieldValueFormatter: ({ record, recordSlug }) => (
            <AssignMemberDropdown
              {...getAssignMemberDropdowmProp({
                disabled,
                record,
                orgMembers,
                customColumnItem,
                recordSlug,
              })}
            />
          ),
        });
      }
    });

    return {
      /** columns to render in table */
      customColumnsToRender,
      /** columns to add in filter options in table */
      customFiltersToAppend,
      /** chips to form when selected in table filter */
      customChipsToFormat,
      /** custom column fields to render in details page */
      customFieldToRenderInDetails,
    };
  };

  const getCustomColumnsToExport = (data) => {
    const customColumnsExport = customColumns.reduce((result, item) => {
      const columnDataToExport = [];

      if (data[item.column_slug]?.exportable_fields)
        data[item.column_slug].exportable_fields.forEach((field) =>
          columnDataToExport.push(`${data[item.column_slug]?.data[field]}`)
        );
      result[item.column_name] = columnDataToExport.join(" ");

      return result;
    }, {});
    return customColumnsExport;
  };

  return {
    appendCustomTableConfig,
    getCustomColumnsToExport,
    customColumnsHeaders,
  };
};

export default useCustomColumnsConfig;
