/**
 * Use this wrapper function
 * 1. To avoid `typeof window === "undefined"` error
 * 2. To fetch any type of value. It uses JSON.parse and JSON.stringify to do so
 */
export const getLocalStorageItem = (key) => {
  try {
    const value = window.localStorage.getItem(key);
    let processedValue = value;
    if (typeof value === "string") {
      /**
       * `JSON.parse()` will fail for the items which were set in
       * the browser before this wrapper function was deployed,
       * because they were plain strings (not processed using
       * `JSON.stringify()`).
       */
      try {
        processedValue = JSON.parse(value);
      } catch {
        processedValue = value;
      }
    }
    return processedValue;
  } catch {
    return null;
  }
};

/**
 * Use this wrapper function
 * 1. To avoid `typeof window === "undefined"` error
 * 2. To store any type of value. It uses JSON.parse and JSON.stringify to do so
 */
export const setLocalStorageItem = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch {}
};

/**
 * Use this wrapper function to avoid `typeof window === "undefined"` error
 */
export const removeLocalStorageItem = (key) => {
  try {
    window.localStorage.removeItem(key);
  } catch {}
};

/**
 * Use this wrapper function
 * 1. To avoid `typeof window === "undefined"` error
 * 2. To store any type of value. It uses JSON.parse and JSON.stringify to do so
 */
export const setSessionStorageItem = (key, value) => {
  try {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  } catch {}
};

/**
 * Use this wrapper function
 * 1. To avoid `typeof window === "undefined"` error
 * 2. To fetch any type of value. It uses JSON.parse and JSON.stringify to do so
 */
export const getSessionStorageItem = (key) => {
  try {
    const value = window.sessionStorage.getItem(key);
    let processedValue = value;
    if (typeof value === "string") {
      /**
       * `JSON.parse()` will fail for the items which were set in
       * the browser before this wrapper function was deployed,
       * because they were plain strings (not processed using
       * `JSON.stringify()`).
       */
      try {
        processedValue = JSON.parse(value);
      } catch {
        processedValue = value;
      }
    }
    return processedValue;
  } catch {
    return null;
  }
};

/**
 * Use this wrapper function to avoid `typeof window === "undefined"` error
 */
export const removeSessionStorageItem = (key) => {
  try {
    window.sessionStorage.removeItem(key);
  } catch {}
};
