import React, { useState, useEffect } from "react";
import { dataProvider, api } from "data";

export const useWistiaAuth = (attemptFetch = true) => {
  const [isWistiaReady, setWistiaReady] = useState(false); // Whether the hook has performed all API calls
  const [isValidAuth, setValidAuth] = useState(false); // Whether the auth token has been verified with the API call to media list
  const [wistiaAccessToken, setWistiaAccessToken] = useState(null); // The access token needed for the API calls
  // TODO: Refresh Token
  const [wistiaProject, setWistiaProject] = useState(null); // The name of the project. Should ideally be Exly or contain Exly in its name
  const [wistiaLoginError, setWistiaLoginError] = useState(null); // Whether there is an error

  const hookKeys = {
    client_id: process.env.REACT_APP_WISTIA_CLIENT_ID,
    redirect_uri: `https://${window.location.host}/oauth_return.html`,
    wistiaUserAccessData: "wistiaUserAccessData",
    wistiaOauthData: "wistiaOauthData",
  };

  const wistiaOauthUrl = `https://app.wistia.com/oauth/authorize?client_id=${hookKeys.client_id}&redirect_uri=${hookKeys.redirect_uri}&response_type=code`;

  // Clears out all caches and resets the hook
  const wistiaLogout = (resetError = false) => {
    setWistiaReady(false);
    window.localStorage.removeItem(hookKeys.wistiaUserAccessData);
    window.localStorage.removeItem(hookKeys.wistiaOauthData);
    setValidAuth(false);
    setWistiaAccessToken(null);
    setWistiaProject(false);
    if (resetError) {
      setWistiaLoginError(null);
    }
  };

  // Tries to fetch a list of media, as a test of the access token's validity
  const getMediaList = (accessTokenProp) => {
    if (!accessTokenProp || typeof accessTokenProp !== "string") {
      throw new Error("Invalid auth token format provided to fetcher");
    }
    return fetch("https://api.wistia.com/v1/medias.json", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessTokenProp}`,
      },
    })
      .then((res) => res.ok)
      .catch(() => {
        setWistiaLoginError("Invalid auth token. Please retry logging in.");
        wistiaLogout();
        return false;
      });
  };

  // Creates a project named "Exly" where the videos will be uploaded to
  const handleCreateProject = (projectURLProp) => {
    return fetch(projectURLProp, {
      method: "POST",
      body: JSON.stringify({ name: "Exly" }),
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((projectData) => {
        if (!projectData?.publicId) throw new Error();
        setWistiaProject(projectData.publicId);
        return true;
      })
      .catch(() => {
        let accountName = null;
        try {
          const wistiaOauthData = window.localStorage.getItem(
            hookKeys.wistiaOauthData
          );
          accountName = JSON.parse(wistiaOauthData || {})?.account_name;
        } catch (err) {}
        setWistiaLoginError(
          <>
            Please create a project named &apos;Exly&apos; in your Wistia
            account.&nbsp;
            {accountName ? (
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={`https://${accountName || ""}.wistia.com/content/create`}
              >
                You can click here to create a new group.
              </a>
            ) : (
              ""
            )}
            <br />
            Alternatively, you can rename an existing project to
            &apos;Exly&apos; as well.&nbsp;
            {accountName ? (
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={`https://${accountName || ""}.wistia.com/content`}
              >
                Click here to access your Wistia groups.
              </a>
            ) : (
              ""
            )}
          </>
        );
        return false;
      });
  };

  // Gets a list of projects for the user and checks if a project named "Exly" exists
  const handleFetchProject = (accessTokenProp) => {
    const projectURL = `https://api.wistia.com/v1/projects.json`;
    return fetch(projectURL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessTokenProp}`,
      },
    })
      .then((res) => res.json())
      .then((projectDataList) => {
        if (!projectDataList?.length) throw new Error();
        const exlyProject = projectDataList.find((g) => g?.name === "Exly");
        if (!exlyProject?.publicId) throw new Error();
        setWistiaProject(exlyProject.publicId);
        return true;
      })
      .catch(() => {
        return handleCreateProject(projectURL);
      });
  };

  // Checks the validity of auth tokens and updates the hook state accordingly
  const updateTokens = async (accessTokenProp, allowLogout = false) => {
    if (await getMediaList(accessTokenProp)) {
      setWistiaAccessToken(accessTokenProp);
      setValidAuth(true);
      if (await handleFetchProject(accessTokenProp)) {
        setWistiaReady(true);
        setWistiaLoginError(null);
        return true;
      }
    } else {
      if (allowLogout) wistiaLogout();
    }
    return false;
  };

  // This function can be used to fetch both the access and refresh token.
  // Just pass a refresh token to make a refresh request instead of access request.
  const fetchAccessToken = (authCode, refreshToken) => {
    return dataProvider
      .custom_request(
        refreshToken
          ? api.fetch_wistia_refresh_token
          : api.fetch_wistia_access_token,
        "POST",
        refreshToken
          ? {}
          : {
              authorization_code: authCode,
              redirect_uri: hookKeys.redirect_uri,
            }
      )
      .then((res) => {
        if (res?.data?.access_token) {
          updateTokens(res?.data?.access_token, true);
          window.localStorage.setItem(
            hookKeys.wistiaUserAccessData,
            JSON.stringify(res?.data)
          );
        }
      })
      .catch(() => {
        setWistiaLoginError("Unable to Log In. Please try again.");
        wistiaLogout();
      });
  };

  // Main handler function to be called by the UI to initiate the login process
  const handleWistiaAuth = () => {
    if (isValidAuth && !wistiaProject) return;

    window.open(
      wistiaOauthUrl,
      "wistiaLogin",
      "height=800,width=500,vAlign=middle,hAlign=center"
    );
    if (window.focus) window.focus();

    const authTokenPromise = new Promise((resolve) => {
      const handleFetchAuthToken = (event) => {
        if (event.key === "wistiaOauthData") {
          resolve(JSON.parse(event.newValue || {}));
        }
      };
      window.addEventListener("storage", handleFetchAuthToken);
    });

    return authTokenPromise
      .then((authData) => {
        return fetchAccessToken(authData.code);
      })
      .catch(() => {
        if (!wistiaLoginError)
          setWistiaLoginError("Invalid auth token. Please retry logging in.");
        wistiaLogout();
      });
  };

  // Perform an initial check of the validity of the cached tokens
  // if present, or try to refresh the token if possible
  useEffect(() => {
    (async () => {
      try {
        const wistiaData = JSON.parse(
          window.localStorage.getItem(hookKeys.wistiaUserAccessData) || {}
        );

        if (typeof wistiaData === "object" && wistiaData?.access_token) {
          if (attemptFetch) {
            if (!(await updateTokens(wistiaData?.access_token, false))) {
              fetchAccessToken(
                wistiaData?.access_token,
                wistiaData?.refresh_token
              );
            }
          }
        }
      } catch (error) {
        wistiaLogout();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect will try to check if a valid project name has been added when the tab is
  // brought into focus again, to avoid the user having to reload the page. Just for the UX
  useEffect(() => {
    const focusCallback = () => {
      if (
        document.visibilityState === "visible" &&
        isValidAuth &&
        !wistiaProject
      ) {
        updateTokens(wistiaAccessToken, true);
        setWistiaLoginError("Checking...");
      }
    };

    document.addEventListener("visibilitychange", focusCallback);

    return () => {
      document.removeEventListener("visibilitychange", focusCallback);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidAuth, wistiaProject, wistiaAccessToken]);

  return {
    isValidWistiaAuth: isValidAuth,
    wistiaAccessToken,
    handleWistiaAuth,
    wistiaProject,
    isWistiaReady,
    wistiaLoginError,
    wistiaLogout,
    setWistiaLoginError,
  };
};
