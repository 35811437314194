import {
  ALPHANUMERIC_DOT_REGEX,
  SPECIAL_CHARACTER_REGEX,
} from "constants/regExpPatterns";
import { is_empty } from "./validations";
import { trimText as _trimText } from "features/Common/modules/DataTypes/utils/strings";

export const EMOJI_REGEX = /\p{Extended_Pictographic}/u;

/**
 * @deprecated
 * DEV - Use features/Common/modules/DataTypes/utils/strings.js
 */
export const trimText = _trimText;

/**
 * Converts underscored string to human readable form
 * @param {*} str
 * @returns
 */
export function humanizeRemensString(str) {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}

const default_params__replace_all_special_chars_with_char = {
  char: "_",
  replaceConsecutive: true,
};

export const replaceAllSpecialCharsWithChar = (
  str,
  {
    char = default_params__replace_all_special_chars_with_char.char,
    replaceConsecutive = default_params__replace_all_special_chars_with_char.char,
  } = default_params__replace_all_special_chars_with_char
) => {
  let returnVal = str.replace(/[^a-zA-Z0-9]/g, char);
  if (replaceConsecutive) {
    returnVal.replace(/_{2,}/g, char); // replace consecutive `_____` by just one `_`
  }
  return returnVal;
};

export const handleLineBreak = (check, text, fallbackText) => {
  return check ? text.replaceAll("\n", "<br />") : fallbackText;
};

export const getShortNotationOfLargeNumbers = (value) => {
  const suffixes = ["", "K", "M", "B", "T"];
  const suffixNum = Math.floor(`${value}`.length / 3);
  const shortValue = parseFloat(
    (suffixNum !== 0 ? value / 1000 ** suffixNum : value).toPrecision(2)
  );
  return suffixNum < suffixes.length - 1
    ? `${shortValue % 1 !== 0 ? shortValue.toFixed(1) : shortValue}${
        suffixes[suffixNum]
      }`
    : `${shortValue % 1 !== 0 ? shortValue.toFixed(1) : shortValue}${
        suffixes[suffixes.length - 1]
      }+`;
};

export const removeLastCharacterIfSingular = (
  count,
  labelWithLastCharacterS
) => {
  // If the count is 1, remove the last character from the label
  // example 1 friend, 2 friends  (in case 1 removing last character i.e. s)
  // Otherwise, return the label as is
  return count === 1
    ? labelWithLastCharacterS?.slice(0, -1)
    : labelWithLastCharacterS;
};

/**
 * Checks if a string contains emojis.
 *
 * @param {string} string - The string to check for emojis.
 * @returns {boolean} True if the string contains emojis, otherwise false.
 */
export const hasEmoji = (string) => (string ? EMOJI_REGEX.test(string) : false);

/**
 * Generates a final form field array name by joining an array of names with dots.
 *
 * @param {string[]} names - An array of names to be joined into a field array name.
 * @returns {string} The final field array name formed by joining the provided names with dots.
 */
export const finalFormFieldArrayNameGenerator = (names) =>
  Array.isArray(names)
    ? names.filter((name) => name && !is_empty(name)).join(".")
    : "";

/**
 * This function returns a boolean to see if a given
 * string contains special characters
 * @param {string} str Any string value
 * @returns boolean if a string contains special characters
 */
export const containsSpecialCharacters = (str) => {
  return SPECIAL_CHARACTER_REGEX.test(str);
};

/**
 * This function returns a boolean to see if a given
 * string for name contains only alphanumeric characters and space or a "."
 * @param {string} str Any string value
 * @returns boolean if name is valid
 */
export const validNameString = (str) => {
  return ALPHANUMERIC_DOT_REGEX.test(str);
};
