import constants from "constants/constants";
import moment from "moment";
import country_codes from "../constants/countryCodes";
import {
  MAX_PHONE_NUMBER_LENGTH,
  MIN_PHONE_NUMBER_LENGTH,
  PHONE_CODES,
} from "constants/phoneNumber.constants";
import { is_empty as _is_empty } from "features/Common/utils/common.utils";

/** @deprecated use is_empty from src/features/Common/utils/common.utils.js */
const is_empty = _is_empty;

function isEmptyImage(str) {
  return is_empty(str) || str === constants.null_display_image;
}

/**
 * @deprecated - use `validURLV2`
 */
export function validURL(str) {
  const regex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  return regex.test(str);
}

/**
 * @deprecated - use `validateURLV2`
 */
export function validateURL(str) {
  if (!validURL(str)) {
    return "Invalid URL";
  }
}

/**
 * checks whether given string is a proper url or not
 * @param {string} str string to be checked
 * @returns true if string is a proper URL, false otherwise.
 */
export function validURLV2(str) {
  try {
    // URL constructor will fail if string is not of proper string structure
    new URL(str);
  } catch {
    return false;
  }
  return true;
}

/**
 * checks whether given string is a proper url or not
 * @param {string} message custom error message to be returned
 * @param {string} str string to be checked
 * @returns an error message if string is NOT a proper URL, returns `undefined` otherwise.
 */
export const validateURLV2 = (message) => (str) => {
  if (!validURLV2(str)) {
    return message || "Invalid URL";
  }
  return undefined;
};

function isYoutubeVideoLink(link) {
  const p =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (link.match(p)) {
    return link.match(p)[1];
  }
  return false;
}

function isValidEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const isValidMobile = (country_code = "+91", value) => {
  let err = true;
  if (is_empty(value)) err = false;
  if (country_code === "+91" && !is_empty(value) && `${value}`.length !== 10)
    err = false;
  if (
    country_code !== "+91" &&
    !is_empty(value) &&
    (`${value}`.length < 7 || `${value}`.length > 20)
  )
    err = false;
  return err;
};

function isValidLength(str, allowed) {
  return str.length < allowed;
}

function is_text_without_space(str) {
  let s = str.trim();
  return s.length !== str.length;
}

function is_whole_number(str) {
  return str % 1 === 0;
}

function containsOnlySpaces(str) {
  return !str.replace(/\s/g, "").length;
}

function isAlphaNumeric(str) {
  return /^[a-z0-9]+$/i.test(str);
}

function isValidPan(str) {
  return /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(str);
}

function isValidGst(str) {
  return /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(str);
}

function checkOffline() {
  if (!window.navigator.onLine) {
    if (
      is_empty(sessionStorage.getItem("no_internet")) ||
      moment().diff(
        moment(sessionStorage.getItem("no_internet")).format(),
        "seconds"
      ) > 1
    ) {
      // alert("Please check you internet connection.");
    }
    sessionStorage.setItem("no_internet", moment().format());
  }
}

function isInteger(value) {
  return value === parseInt(value, 10);
}

function isFloat(value) {
  return value === parseFloat(value);
}

// For the cases where the country code has been recorded as the country name instead of the numeric country code
function fixCountryCode(dataCountryCode) {
  if (
    dataCountryCode &&
    dataCountryCode?.length > 0 &&
    dataCountryCode[0] !== "+"
  ) {
    const countryMatch = country_codes.filter(
      (c) => c.name === dataCountryCode
    );
    if (countryMatch.length > 0) return countryMatch[0].dial_code;
  }
  // If no suitable amendment was made, just return the base value
  return dataCountryCode;
}

const check_url_instagram = (value) => {
  let instaRegex =
    /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\\.]+)/im;
  if (is_empty(value)) return;
  if (!is_empty(value) && instaRegex.test(value)) return;
  return `Invalid instagram link. Please check the entered link`;
};

const check_url_fb = (value) => {
  let fbRegex =
    /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\\-]*)?/;
  if (is_empty(value)) return;
  if (!is_empty(value) && fbRegex.test(value)) return;
  return `Invalid facebook link. Please check the entered link`;
};

const check_url_twitter = (value) => {
  let twitterRegex =
    /(?:(?:http|https):\/\/)?(?:www\.)?(?:twitter\.com|twitt\.er)\/([A-Za-z0-9-_\\.]+)/im;
  if (is_empty(value)) return;
  if (!is_empty(value) && twitterRegex.test(value)) return;
  return `Invalid twitter link. Please check the entered link`;
};

const check_url_linkedIn = (value) => {
  let linkedRegex =
    /(?:(?:http|https):\/\/)?(?:www\.)?(?:linkedin\.com|linked\.in)\/([A-Za-z0-9-_/\\.]+)/im;
  if (is_empty(value)) return;
  if (!is_empty(value) && linkedRegex.test(value)) return;
  return `Invalid linkedIn link. Please check the entered link`;
};

const check_url_telegram = (value) => {
  let telegramRegex =
    /(?:(?:http|https):\/\/)?(?:www\.)?(?:t\.me)\/([A-Za-z0-9-_\\.]+)/im;
  if (is_empty(value)) return;
  if (!is_empty(value) && telegramRegex.test(value)) return;
  return `Invalid telegram link. Please check the entered link`;
};

const validateNumber = (value, country_code = "+91") => {
  let err = false;
  if (country_code === "+91" && !is_empty(value) && `${value}`.length !== 10)
    err = true;
  if (
    country_code !== "+91" &&
    !is_empty(value) &&
    (`${value}`.length < 7 || `${value}`.length > 20)
  )
    err = true;
  return err;
};

const check_url_whatsapp = (value) => {
  if (is_empty(value)) return;
  let whatsapp_string = value.split("https://api.whatsapp.com/send?phone=");
  if (whatsapp_string.length === 2) return;
  return `Invalid whatsapp link. Please check the entered link`;
};

export const check_url_tiktok = (value) => {
  const tiktokRegex = /https?:\/\/(vt.|www.)?tiktok.com\/([A-Za-z1-9@])\w+/i;
  if (is_empty(value)) return;
  if (!is_empty(value) && tiktokRegex.test(value)) return;
  return `Invalid tiktok link. Please check the entered link`;
};
export const check_url_youtube = (value) => {
  let youtubeRegex =
    /(?:(?:http|https):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/([A-Za-z0-9-_\\.]+)/im;
  // let youtubeRegex = /^(https?\\:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/;
  if (is_empty(value)) return;
  if (!is_empty(value) && youtubeRegex.test(value)) return;
  return `Invalid youtube link. Please check the entered link`;
};
export const checkYoutubeChannelUrl = (value) => {
  let youtubeRegex =
    /http(s)?:\/\/(www|m).youtube.com\/((channel|c)\/)?(?!feed|user\/|watch\?)([a-zA-Z0-9-_.])*.*/im;
  if (is_empty(value)) return;
  if (!is_empty(value) && youtubeRegex.test(value)) return;
  return `Invalid youtube channel link. Please check the entered link`;
};

export const check_url_spotify = (value) => {
  let spotifyRegex =
    /(https?:\/\/open.spotify.com\/(track|user|artist|album|show)\/[a-zA-Z0-9]+(\/playlist\/[a-zA-Z0-9]+|)|spotify:(track|user|artist|album|show):[a-zA-Z0-9]+(:playlist:[a-zA-Z0-9]+|))/;
  if (is_empty(value)) return;
  if (!is_empty(value) && spotifyRegex.test(value)) return;
  return `Invalid spotify link. Please check the entered link`;
};

// Source: https://final-form.org/docs/react-final-form/examples/field-level-validation
export const composeValidators =
  (...validators) =>
  (...args) =>
    validators.reduce(
      (error, validator) => error || validator?.(...args),
      undefined
    );

const isGoogleDriveFile = (url) => {
  const regex = /^https:\/\/drive\.google\.com\/file\/d\/(.*?)\/.*?$/;
  return regex.test(url);
};

export const isDefined = (data) => typeof data !== "undefined";

export const isNull = (data) => data === null;

export const dataExists = (data) => isDefined(data) && !isNull(data);

export const isFunction = (data) => typeof data === "function";

function isHTMLString(str) {
  const htmlRegex = /<[^>]*>/; // This regular expression matches any HTML tags
  return htmlRegex.test(str);
}
/**
 * Checks valid phone numbers without + , need to input number with countrycode eg:- 918643512135
 * @param {*} number
 * @returns True is Valid phone number else false
 */
export const isValidPhoneNumber = (number) => {
  return /^\d{7,15}$/.test(number);
};

/**
 *
 * @param {*} phoneNumbers Array of phone numbers
 * @returns true if All numbers are valid else false
 */
export const checkAllPhoneNumbersValid = (phoneNumbers) => {
  for (const number of phoneNumbers) {
    if (!isValidPhoneNumber(number)) {
      return false;
    }
  }
  return true;
};

export const checkRequiredPhoneNumberValid = (phoneNumber) => {
  if (is_empty(phoneNumber)) {
    return "Phone Number is Required";
  }
  let formattedNumber = phoneNumber.replace(/[\s-+]/g, "");

  let is_india = false;

  if (formattedNumber?.substring(0, 2) === PHONE_CODES.INDIA) {
    is_india = true;
  }
  if (is_india && formattedNumber?.length < MAX_PHONE_NUMBER_LENGTH) {
    return "Invalid phone number";
  }

  if (!is_india && formattedNumber?.length < MIN_PHONE_NUMBER_LENGTH) {
    return "Invalid phone number";
  }
};

export {
  isValidMobile,
  check_url_whatsapp,
  check_url_telegram,
  isInteger,
  fixCountryCode,
  is_empty,
  isValidLength,
  is_text_without_space,
  is_whole_number,
  containsOnlySpaces,
  isEmptyImage,
  isAlphaNumeric,
  isValidPan,
  isValidGst,
  checkOffline,
  isValidEmail,
  check_url_instagram,
  check_url_fb,
  check_url_twitter,
  check_url_linkedIn,
  isYoutubeVideoLink,
  validateNumber,
  isFloat,
  isGoogleDriveFile,
  isHTMLString,
};
