import { DRM_COMPATIBILITY_EXPLANATION_URL } from "constants/recordedContent";
import {
  app_pathnames,
  app_route_ids,
  app_route_keys,
} from "constants/urlPaths";
// @ts-ignore
import { appendURLSearchParams } from "@my-scoot/exly-react-component-lib/build/cjs/utils/common/url";
import { youtubeVideoUrlFormats } from "constants/urls.constant";
import { is_empty } from "./validations";
import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";

/**
 * we have to use this method instead of `new URL(url)` since URL constructor fails on non proper urls.
 * And at some places we jut pass routes(see this file `utils/constants/api.ts`) to this.
 */
export const getUrlPathnameOriginSearchHash = (url) => {
  const indexOfUrlSearch = url.indexOf("?");
  const indexOfUrlSearchExists = indexOfUrlSearch >= 0;
  const indexOfUrlHash = url.indexOf("#");
  const indexOfUrlHashExists = indexOfUrlHash >= 0;

  let hash = "";
  if (indexOfUrlHashExists) {
    hash = url.slice(indexOfUrlHash);
  }
  const urlWOHash = url.replace(hash, "");

  let search = "";
  if (indexOfUrlSearchExists) {
    search = urlWOHash.slice(indexOfUrlSearch);
  }

  const originAndPathname = urlWOHash.replace(search, "");

  return { originAndPathname, search, hash };
};

export const appendUrlParams = appendURLSearchParams;

export const deleteUrlParams = (url, params) => {
  const urlObj = getUrlPathnameOriginSearchHash(url);
  const newUrlOriginAndPathname = urlObj.originAndPathname;
  let newUrlSearch = urlObj.search;
  const newUrlHash = urlObj.hash;

  const newUrlParams = new URLSearchParams(newUrlSearch);
  params.forEach((i) => newUrlParams.delete(i));
  newUrlSearch = newUrlParams.toString();
  newUrlSearch = (newUrlSearch ? "?" : "") + newUrlSearch;
  return newUrlOriginAndPathname + newUrlSearch + newUrlHash;
};

/**
 * React admin has following url structure
 * https://<domain>/#/<route>?<urlparams>
 * this function parses this string
 * @returns `new URLSearchParams` object
 */
/** @deprecated use getRAUrlParams from features/Common/utils/url.utils.js */
export const getRAUrlParams = (url = window.location.href) => {
  const indexOfParamStart = url.indexOf("?");
  const indexOfParamStartExists = indexOfParamStart >= 0;
  let search = "";
  if (indexOfParamStartExists) {
    search = url.slice(indexOfParamStart);
  }
  return new URLSearchParams(search);
};

/**
 * React admin has following url structure
 * https://<domain>/#/<route>?<urlparams>
 * this function parses this string
 * @returns pathname
 */
export const getRAUrlPathName = (url = window.location.href) => {
  const indexOfPathname = url.indexOf("/#/");
  const indexOfPathnameExists = indexOfPathname >= 0;
  const indexOfParamStart = url.indexOf("?");
  const indexOfParamStartExists = indexOfParamStart >= 0;
  let pathname = url;
  if (indexOfParamStartExists) {
    pathname = pathname.slice(0, indexOfParamStart);
  }
  if (indexOfPathnameExists) {
    pathname = pathname.slice(indexOfPathname).replace("/#/", "");
  }
  return pathname;
};

export const getWhatsAppLink = (phone, { text = "" } = { text: "" }) =>
  appendUrlParams(`https://wa.me/${phone}`, { text: encodeURIComponent(text) });

export const openWhatsAppLink = (phone) => {
  window.open(getWhatsAppLink(phone), "_blank", "noopener,noreferrer");
};

export const openGoogleUrlInspectionTool = () => {
  window.open(
    "https://support.google.com/webmasters/answer/9012289?hl=en",
    "_blank",
    "noopener,noreferrer"
  );
};

export const openGoogleSearchConsole = () => {
  window.open(
    "https://search.google.com/search-console/welcome?utm_source=about-page",
    "_blank",
    "noopener,noreferrer"
  );
};

export const openRobotTxt = () => {
  const siteUrl = getCreatorHostsiteURL().url;

  window.open(`${siteUrl}/robots.txt`, "_blank", "noopener,noreferrer");
};

export const openSiteMap = () => {
  const siteUrl = getCreatorHostsiteURL().url;
  window.open(`${siteUrl}/sitemap.xml`, "_blank", "noopener,noreferrer");
};

export const getListingShareUrl = (listing) => {
  const { metadata, url_slug, uuid, parent_listing_uuid, plan_slug } = listing;
  const url = getCreatorHostsiteURL().url;

  if (plan_slug) return `${url}/plan/${plan_slug}`;

  if (url_slug) {
    return `${url}/${url_slug}`;
  }

  if (metadata?.share_url) {
    return metadata.share_url;
  }

  if (parent_listing_uuid) {
    return `${url}/plan/${uuid}`;
  }

  return `${url}/${uuid}`;
};

export const onOpenSearchConsoleHelpCenter = () => {
  window.open(
    "https://support.google.com/webmasters/answer/96568?hl=en#:~:text=Perhaps%20you%20added%20the%20wrong,your%20site%20in%20search%20results",
    "_blank",
    "noopener,noreferrer"
  );
};

export const onOpenWhyMyReportMissingPage = () => {
  window.open(
    "https://support.google.com/webmasters/answer/7474347?visit_id=638122062579279945-910744442&rd=1",
    "_blank",
    "noopener,noreferrer"
  );
};

/**
 * Provides the upated url for youtube urls.
 * For example it will convert youtube shorts url to working urls.
 * @param {*} url video url in string
 * @returns parsed video url if youtube format.
 */
export const parseYTUrl = (url) => {
  let parsedYTVideoUrl = url;

  youtubeVideoUrlFormats.some((format) => {
    if (url.startsWith(format)) {
      const newUrl = deleteUrlParams(url, ["list", "index"]);

      // shows copy link button instead of share and watch later button in youtube videos
      parsedYTVideoUrl = newUrl.replace(
        format,
        "https://www.youtube-nocookie.com/embed/"
      );
      return true;
    }
    return false;
  });

  return parsedYTVideoUrl;
};

/* Helper function to generate url filter as React Admin does to solve issues with setFilters and navigation */
export const generateRAurl = (
  pathname,
  filterValues,
  order = "ASC",
  page = 1,
  perPage = 50
) => {
  const encodedFilter = encodeURIComponent(JSON.stringify(filterValues));
  return `${pathname}?filter=${encodedFilter}&order=${order}&page=${page}&perPage=${perPage}`;
};

export const onOpenDrmCompatibilityExplanation = () => {
  window.open(
    DRM_COMPATIBILITY_EXPLANATION_URL,
    "_blank",
    "noopener,noreferrer"
  );
};

/**
 * naviigates to a route present in src/constants/urlPaths.js
 * @param {*} key app_route_key
 * @param {*} options args to be passed to route (optional)
 */
export const navigateTo = (key, options = {}) => {
  let hash = "";
  if (
    key &&
    app_pathnames[app_route_keys[key]] &&
    !is_empty(options?.args) &&
    typeof app_pathnames[app_route_keys[key]] === "function"
  ) {
    hash = `${app_pathnames[app_route_keys[key]](options?.args)}`;
  } else if (key && app_route_ids[app_route_keys[key]]) {
    hash = `${app_route_ids[app_route_keys[key]]}`;
  } else {
    hash = `${app_route_ids[app_route_keys.app_home]}`;
  }

  // checking if first char of hash is / then removing it
  if (hash[0] === "/") {
    hash = hash.replace("/", "");
  }
  window.location.href = `${window.location.origin}/#/${hash}`;
};

/**
 * Extracts the YouTube video ID from the provided YouTube video URL.
 *
 * @param {string} url - The YouTube video URL.
 * @returns {string} - The YouTube video ID.
 */
export function getYoutubeVideoId(url) {
  let ID = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_-]/i);
    ID = ID[0];
  } else {
    ID = url;
  }
  return ID;
}

/**
 * Generates the YouTube video embed URL from the provided YouTube video URL.
 *
 * @param {string} videoLink - The YouTube video URL.
 * @returns {string} - The YouTube video embed URL.
 */
export const getYoutubeVideoEmbed = (videoLink) => {
  return `https://youtube.com/embed/${getYoutubeVideoId(videoLink)}`;
};
