import { useRef, useEffect } from "react";

/**
 * A custom hook that debounces any fast-triggering function. The function will only be invoked after
 * the specified delay has elapsed since the last function call.
 *
 * @param {Function} callback - The function to debounce. This function can take any number of arguments.
 * @param {number} delay - The amount of time (in milliseconds) to wait before invoking the callback after the last call.
 * @returns {Function} debouncedFunction - A function that, when called, will reset its internal timer and
 *                                         delay the execution of the `callback` function until after the `delay` period.
 */
export function useDebounceAction(callback, delay) {
  const timeoutRef = useRef();

  const debouncedFunction = (...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  // Clear the timeout when the component is unmounted or when delay changes
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [delay]);

  return debouncedFunction;
}
