import local_storage_keys from "constants/LocalStorage.constants";
import constants from "constants/constants";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { useHistory } from "react-router";
import { getCustomerByGuestName } from "ui/pages/schedule/BookedSession/BookedSession.helpers";
import { logError } from "utils/error";
import { setLocalStorageItem } from "utils/getterAndSetters";

// @muskan Todo (Revamp in R4), this logic to be maintained via url instead of session storage,hence not moving this to features folder
const useCustomerDetails = () => {
  const history = useHistory();

  const setCustomerInLocalStorage = (record) => {
    setLocalStorageItem(local_storage_keys.CUSTOMER, record);
  };

  const setGuestUserNameInLocalStorage = (record) => {
    /** setLocalStorageItem is not used here (intentionally) as that function performs json.stringify on the value and this key is being getfromLocalstorage (window.localStorage.getItem) at many places which will start breaking.
    TODO @DEV, replace all the setters & getter of this (local_storage_keys.GUEST_USER_NAME) key altogether and perform sanity.
    */
    window.localStorage.setItem(
      local_storage_keys.GUEST_USER_NAME,
      record.username
    );
  };

  const setEntityTypeInLocalStorage = (entityType) => {
    /** setLocalStorageItem is not used here (intentionally) as that function performs json.stringify on the value and this key is being getfromLocalstorage (window.localStorage.getItem) at many places which will start breaking.
    TODO @DEV, replace all the setters & getter of this (local_storage_keys.ENTITY_TYPE) key altogether and perform sanity.
    */
    /** entity Type represents 1:Leads 2:Customers, We must store these values in local storage to retain them from the previous page. Upon navigating src/redux/customReducers.jsto a new page, we will make an API call for fresh data. This stored data should persist even after a page refresh. */
    window.localStorage.setItem(local_storage_keys.ENTITY_TYPE, entityType);
  };

  const handleCustomerDetailNavigation = ({ record, entityType }) => {
    // TODO @dev this function is used to navigate to /customer-details from leads & customer's table , this uses localstorage to save params instead of passing them to route. (Need revamp)
    setCustomerInLocalStorage(record);
    setGuestUserNameInLocalStorage(record);
    setEntityTypeInLocalStorage(entityType);

    history.push(`${app_route_ids[app_route_keys.customer_details]}`);
  };

  const handleBookingNavigationToCustomer = (record) => {
    getCustomerByGuestName({ guest_username: record.username })
      .then(({ data }) => {
        const [customerData] = data?.all_customers;
        handleCustomerDetailNavigation({
          record: customerData,
          entityType: constants.entity_type.customers.value,
        });
      })
      .catch((error) => {
        logError({
          error,
          occuredAt: "src/utils/hooks/useCustomerDetails.js",
          when: "calling getCustomersByGuestName",
        });
      });
  };

  return {
    handleCustomerDetailNavigation,
    setCustomerInLocalStorage,
    setGuestUserNameInLocalStorage,
    setEntityTypeInLocalStorage,
    handleBookingNavigationToCustomer,
  };
};

export default useCustomerDetails;
